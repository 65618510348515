import React from "react";

import Menu from "./components/Menu";
function App() {
  return (
    <div>
      <Menu />
    </div>
  );
}

export default App;
