import React from "react";
// import Logo from "../../assets/img/Logo.png";
import Logo from "../../assets/img/Logo_O.png";

import ButtonLink from "./components/ButtonLink";
import Button from "./components/Button";

import "./Menu.css";
const Menu = () => {
  return (
    <nav className="Menu">
      <a href="/">
        <img className="Logo" src={Logo} alt="ABSFlix Logo" />
      </a>

      {/*<ButtonLink href="/" className="ButtonLink">
        Novo video
        </ButtonLink> 
      */}
      <Button as="a" href="/" className="ButtonLink">
        Novo video
      </Button>
    </nav>
  );
};

export default Menu;
